<template>
  <a-modal
    :title="title"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleSubmit"
    @cancel="handleCancel">

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item
          label="角色名称"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input placeholder="请输入角色名称" v-decorator="['roleName', {rules: [{required: true, message: '请输入角色名称！'}]}]" />
        </a-form-item>

        <a-form-item
          label="角色键名"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input placeholder="请输入角色键名" v-decorator="['roleKey', {rules: [{required: true, message: '请输入角色键名！'}]}]" />
        </a-form-item>

        <a-form-item
          label="角色状态"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          v-if="!!roleId"
        >
          <a-radio-group v-decorator="['status', {rules: [{required: true, message: '请选择角色状态'}]}]">
            <a-radio :value="1">
              正常
            </a-radio>
            <a-radio :value="0">
              停用
            </a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item
          label="排序号"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input-number placeholder="请输入排序号" v-decorator="['sort', {rules: [{required: true, message: '请输入排序号！'}],initialValue: 0}]" />
        </a-form-item>

        <a-form-item
          label="备注"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            type="textarea"
            placeholder="请输入备注"
            v-decorator="['description']" />
        </a-form-item>

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { add, edit } from '@/api/user-rights/rolemanage'

  export default {
    data () {
      return {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 15 }
        },
        visible: false,
        confirmLoading: false,
        form: this.$form.createForm(this),
        roleId: undefined,
        title: undefined
      }
    },
    methods: {
      edit (record) {
        this.title = '编辑角色'
        this.roleId = record.roleId
        this.visible = true
        setTimeout(() => {
          this.form.setFieldsValue({
            roleName: record.roleName,
            roleKey: record.roleKey,
            status: record.status,
            sort: record.sort,
            description: record.description
          })
        }, 100)
      },
      add () {
        this.title = '新增角色'
        this.visible = true
      },
      /**
       *提交
       */
      handleSubmit () {
        const { form: { validateFields } } = this
        this.confirmLoading = true
        validateFields((errors, values) => {
          if (!errors) {
            if (this.roleId) {
              this.editSubmit(values)
            } else {
              this.addSubmit(values)
            }
          } else {
            this.confirmLoading = false
          }
        })
      },
      /**
       * 编辑提交
       */
      editSubmit (values) {
        edit(Object.assign(values, { roleId: this.roleId })).then((res) => {
          this.confirmLoading = false
          if (res.code === 200) {
            this.$message.success('编辑成功')
            this.handleCancel()
            this.$emit('ok', values)
          }
        }).finally((res) => {
          this.confirmLoading = false
        })
      },
      /**
       *新增提交
       */
      addSubmit (values) {
        add(Object.assign(values, { status: 1 })).then((res) => {
          this.confirmLoading = false
          if (res.code === 200) {
            this.$message.success('新增成功')
            this.handleCancel()
            this.$emit('ok', values)
          }
        }).finally((res) => {
          this.confirmLoading = false
        })
      },
      /**
       * 取消
       */
      handleCancel () {
        this.form.resetFields()
        this.roleId = undefined
        this.visible = false
        this.title = undefined
      }
    }
  }
</script>
